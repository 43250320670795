<template>
  <div>
    <portal to="title-page">
      <h1>Categorias de los extras</h1>
    </portal>
    <v-data-table v-if="categories != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="categories"
      item-key="id"
      :search="search"
      fixed-header
      class="elevation-1"
    >
      <!-- show-select -->
      <!-- :single-select="singleSelect" -->
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Buscar"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-btn @click="$router.push({path:'/backend/admin/categories-extras/create'})" small color="primary"><v-icon>mdi-plus-thick</v-icon> Categoria</v-btn>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <!-- <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch> -->
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon 
          class="mr-2"
          @click="$router.push({path:`/backend/admin/categories-extras/${item.id}/edit`})"
        >
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:item.status="{ item }">
        <v-btn @click="setItem(item)"
          small :color="item.status == 'active' ? 'green' : 'red'" rounded
          dark>
          <v-icon small>{{mdiPencil}}</v-icon>
          {{ item.status }}
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialogStatus" max-width="600px" v-if="editingItem">
      <v-card >
        <v-card-title class="">Editar status de la categoria: <b>{{editingItem.name}}</b></v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
          ref="formlang"
          v-model="validFormStatus"
            lazy-validation
          >
            <v-select
              v-model="editingItem.status"
              :items="selectStatus"
              item-text="text"
              label="Status"
              item-value="value"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  @click="closeDialogStatus()">Cerrar</v-btn>
          <v-btn color="primary"  :disabled="!validFormStatus"
          @click="updateStatus">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import CategoryExtra from '@/models/CategoryExtra';
  import { mdiClose, mdiPencil } from '@mdi/js';

  export default {
    name:'CategoriesExtras',
    data:() => {
      return {
        mdiPencil: mdiPencil,
        mdiClose:mdiClose,
        snackbar:{
          show:false,
          message:'',
          color:''
        },
        search:'',
        categoriesModel : new CategoryExtra,
        categories : [],
        //categories : null,
        selected : [],
        tableHeaders:[
          {
            text: 'Nombre',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          /*{
            text: 'Descripcion',
            align: 'start',
            sortable: false,
            value: 'description',
          },*/
          {
            text: 'Status',
            align: 'start',
            sortable: true,
            value: 'status',
          },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        editingItem:null,
        dialogStatus: false,
        selectStatus: [
          { value: "active", text: "Active" },
          { value: "inactive", text: "Inactive" },
        ],
        validFormStatus:true
      }
    },
    methods:{
      deleteItem(item) {
        let status = item.status == 'active' ? 'inactive' : 'active';
        //let txtStatus = item.status == 'active' ? 'desactivo' : 'activo';
        let txtStatus1 = item.status == 'active' ? 'desactivo' : 'activo';
        let txtStatus2 = item.status == 'active' ? 'desactivar' : 'activar';
        this.$dialog.confirm(`¿Desea ${txtStatus2} la categoria <strong>${item.name}</strong>?`, {
          loader: true // default: false - when set to true, the proceed button shows a loader when clicked.
          // And a dialog object will be passed to the then() callback
        })
        .then((dialog) => {
            //this.categoriesModel.delete(item.id).then(() => {
            this.categoriesModel.update(item.id, {status:status}).then(() => {
              dialog.close();
              this.dialog.show = false;
              this.snackbar.show = true;
              this.snackbar.color = 'green';
              this.snackbar.message = `La categoria se ${txtStatus1} correctamente`;
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
              dialog.close();
              this.snackbar.show = true;
              this.snackbar.color = 'red';
              this.snackbar.message = `Error al ${txtStatus2} la categoria, por favor contacte al Administrador si el problema persiste`;
            });
          })
        .catch(() => {
          // Triggered when cancel button is clicked
          console.log('Delete aborted');
        });
      },
      updateStatus() {
        let loading = this.$loading.show();
        // console.log("hola", changeStatus);
        // console.log("hola", this.itemIdStatus);
        this.categoriesModel
          .update(this.editingItem.id, { status: this.editingItem.status })
          .then(() => {
            loading.hide();
            this.snackbar.show = true;
            this.snackbar.color = "green";
            this.snackbar.message = `El status se actualizo correctamente`;
            this.dialogStatus = false;
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.message = `Error al cambiar el status de la categoria, por favor contacte al Administrador si el problema persiste`;
          });
      },
      setItem(item) {
        this.editingItem = {... item};
        // console.log("valor de item id", this.itemIdStatus.id);
        this.dialogStatus = true;
      },
      closeDialog() {
        this.dialog.show = false;
        this.editing = false;
        this.editingItem = {
          name:'',
          description:''
        }
      },
      closeDialogStatus() {
      this.dialogStatus = false;
    },
    },
    mounted() {
      this.categoriesModel.getAll().onSnapshot(qSnap => {
        this.categories = [];
        qSnap.forEach(doc => {
          let category = doc.data();
          category.id = doc.id;
          this.categories.push(category)
        })
      })
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    }
  }
</script>
<style>
</style>