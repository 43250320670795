import BaseModel from './BaseModel';
//import Vue from 'vue';
//import lodash from 'lodash';
//import {DataConverter as CategoryExtraDataConverter} from '@/FirestoreDataConverters/CategoryExtra.js';
import {DataConverter, CategoryExtra as CategoryExtraData} from '@/FirestoreDataConverters/CategoryExtra.js';


export default class CategoryExtra extends BaseModel{
  constructor(options) {
    super(options);
  }
  findById(docId) {
    return this.db.collection('categoriesExtras').doc(docId).withConverter(DataConverter);
  }
  getAll() {
    return this.db.collection('categoriesExtras').withConverter(DataConverter);
    //return this.db.collection('categoriesExtras').withConverter(CategoryExtraDataConverter);
  }
  add(data, language) {
    return this.db.collection('categoriesExtras').withConverter(DataConverter)
    .add(new CategoryExtraData(data)).then(docRef => {
      return docRef.collection('categoryExtraLanguages').doc(language.id).set({
        name:data.name,
        description:data.description,
        language:language.name
      })
    })

  }
  update(id, data) {
    return this.db.collection('categoriesExtras').doc(id).update(data);
  }
  delete(id) {
    return this.db.collection('categoriesExtras').doc(id).delete();
  }
}