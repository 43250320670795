class CategoryExtra {
  id;
  constructor(data) {
    this.id = data.id || '';
    this.name = data.name || '';
    this.description = data.description || '';
    this.status = data.status || '';
  }
}
var DataConverter = {
  toFirestore: function(categoryExtra) {
    return {
      name: categoryExtra.name,
      description: categoryExtra.description,
      status: categoryExtra.status,
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new CategoryExtra(data)
  }
}
export {CategoryExtra, DataConverter} 